import React, { Component, createRef } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';

import {
  TournamentsBannersList as TournamentsBannersListCore,
  RouteLink,
  FormattedTag,
} from 'core/components';
import {
  withCoreComponent,
  withBonusActions,
  withModalActions,
  withUser,
} from 'core/hocs';
import { isEmptyOrNil, getIntlKeyByEnv, getIsAffiliate } from 'core/helpers';
import { USER_FIELDS } from 'core/constants';

import { Carousel } from 'components/carousel/carousel';
import { IconArrowDown } from 'components/icons/icon-arrow-down/icon-arrow-down';
// import { BannerTournamentSlide } from '../banner-tournament-slide/banner-tournament-slide';
import colors from 'customizations/js/color-variables';
import { withPixelRatio } from '../../../hocs/with-pixel-ratio';
import { getBackgroundStyles } from '../../../helpers/images';
import {
  MODAL_IDS, CURRENCY_SYMBOLS, BONUSES_ACTION_TYPES, BANNER_CATEGORIES
} from '../../../constants';

import './banners-slider.scss';

const filterBannersItemsByCategory = (items, isLiveGamesCategory, isAffiliate) => {
  if (isEmptyOrNil(items)) return [];

  return items.filter(({ items: { common, affiliate } }) => {
    const pageCategory = isLiveGamesCategory ? BANNER_CATEGORIES.LIVE_PAGE : BANNER_CATEGORIES.HOME_PAGE;
    const itemCategory = (isAffiliate && affiliate) ? affiliate.category : common.category;

    return [pageCategory, BANNER_CATEGORIES.COMMON].includes(itemCategory);
  });
};

export class BannersSliderUI extends Component {
  static propTypes = {
    bonusesItems: PropTypes.arrayOf(PropTypes.shape()),
    items: PropTypes.shape(),
    setPendingBonus: PropTypes.func.isRequired,
    openModalAndCloseOthers: PropTypes.func.isRequired,
    isUserRegistered: PropTypes.bool.isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
    isSignUpCompleted: PropTypes.bool.isRequired,
    pixelRatio: PropTypes.string.isRequired,
    defaultCurrency: PropTypes.string.isRequired,
    userData: PropTypes.shape(),
    userTrackingCodes: PropTypes.shape(),
    isLiveGamesCategory: PropTypes.bool,
  };

  static defaultProps = {
    bonusesItems: [],
    items: {},
    userData: {},
    userTrackingCodes: {},
    isLiveGamesCategory: false,
  };

  state = {
    isCarouselShown: false,
  };

  carouselBgRef = createRef();

  componentDidMount() {
    this.setState({ isCarouselShown: true });
  }

  componentDidUpdate({ bonusesItems: prevBonusesItems }) {
    const { bonusesItems } = this.props;

    if (!R.equals(bonusesItems, prevBonusesItems)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isCarouselShown: false }, () => {
        setTimeout(() => {
          this.setState({ isCarouselShown: true });
        });
      });
    }
  }

  openModal = () => {
    const {
      isUserRegistered,
      isUserLoggedIn,
      isSignUpCompleted,
      openModalAndCloseOthers,
    } = this.props;

    let modalId;

    if (!isUserRegistered && !isUserLoggedIn) {
      modalId = MODAL_IDS.SIGN_UP;
    } else if (isUserRegistered && !isUserLoggedIn) {
      modalId = MODAL_IDS.SIGN_IN;
    } else if (isUserLoggedIn && !isSignUpCompleted) {
      modalId = MODAL_IDS.COMPLETE_SIGN_UP;
    } else if (isUserLoggedIn && isSignUpCompleted) {
      modalId = MODAL_IDS.DEPOSIT;
    }

    openModalAndCloseOthers(modalId);
  };

  activateBonus = (e) => {
    const { currentTarget: { dataset: { id, min } } } = e;
    const { isUserLoggedIn, isSignUpCompleted, setPendingBonus } = this.props;

    if (isUserLoggedIn && isSignUpCompleted && id && min) {
      e.preventDefault();
      setPendingBonus({ bonusPlanId: id, minAmount: min });
    }

    this.openModal();
  };

  beforeChange = (oldIdx, newIdx) => {
    this.carouselBgRef.current.slickGoTo(newIdx);
  };

  render() {
    const {
      // items,
      bonusesItems,
      pixelRatio,
      userTrackingCodes,
      userData,
      isUserLoggedIn,
      defaultCurrency,
      isLiveGamesCategory,
    } = this.props;

    const { isCarouselShown } = this.state;

    const isAffiliate = getIsAffiliate(userData, userTrackingCodes);
    const userCurrency = isUserLoggedIn ? userData.currency : defaultCurrency;
    const currencyCode = CURRENCY_SYMBOLS[userCurrency];
    const getValueFromCurrencies = obj => R.path(['currencies', userCurrency])(obj);

    const filteredBannersItems = filterBannersItemsByCategory(bonusesItems, isLiveGamesCategory, isAffiliate);

    return (
      <div className="position-relative banners-slider-container">
        {isCarouselShown && (
          <>
            <div className="banners-slider position-relative">
              <Carousel
                settings={{
                  dots: true,
                  infinite: true,
                  arrows: true,
                  fade: true,
                  lazy: true,
                  autoplay: true,
                  autoplaySpeed: 5000,
                  responsive: [
                    {
                      breakpoint: 1279,
                      settings: {
                        arrows: false,
                      },
                    },
                  ],
                }}
                beforeChange={this.beforeChange}
                iconRight={<IconArrowDown />}
                iconLeft={<IconArrowDown />}
              >
                {!isEmptyOrNil(filteredBannersItems) ? filteredBannersItems.map((data) => {
                  const {
                    items: {
                      common,
                      affiliate,
                    },
                  } = data || {};

                  const {
                    id,
                    smallTitle,
                    largeTitle,
                    buttonTitleForUser,
                    buttonTitleForGuest,
                    buttonUrl,
                    buttonTitleColor,
                    buttonColor,
                    bonusId,
                    actionType,
                    minDeposit,
                    isNewTabLink,
                    descriptionColor,
                    titleColor,
                    isImageOnlyBanner
                  } = isAffiliate && affiliate ? affiliate : common;

                  // const { intlKey: christmasTitleValue } = christmasTitle;
                  const { intlKey: buttonTitle } = isUserLoggedIn ? buttonTitleForUser : buttonTitleForGuest;

                  const isBtnWithLink = buttonUrl && actionType === BONUSES_ACTION_TYPES.LINK;
                  const isRelativeLink = isBtnWithLink && (buttonUrl || '').startsWith('/');

                  const BannerSliderItem = ({ className, children }) => {
                    if (isImageOnlyBanner) {
                      if (isBtnWithLink) {
                        if (isRelativeLink) {
                          return (
                            <RouteLink
                              className={className}
                              to={buttonUrl}
                              {
                                ...(isNewTabLink
                                  ? {
                                    target: '_blank',
                                    rel: 'noopener noreferrer',
                                  }
                                  : {})
                              }
                            >
                              {children}
                            </RouteLink>
                          );
                        }

                        return (
                          <a
                            className={className}
                            href={buttonUrl}
                            {
                              ...(isNewTabLink
                                ? {
                                  target: '_blank',
                                  rel: 'noopener noreferrer',
                                }
                                : {})
                            }
                          >
                            {children}
                          </a>
                        );
                      }

                      return (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={this.activateBonus}
                          data-id={getValueFromCurrencies(bonusId)}
                          data-min={getValueFromCurrencies(minDeposit)}
                          onKeyDown={this.activateBonus}
                          className={className}
                        >
                          {children}
                        </div>
                      );
                    }

                    return (
                      <div className={className}>
                        {children}
                      </div>
                    );
                  };

                  return (
                    <BannerSliderItem key={id} className="banners-slider-item w-100">
                      <div className="banners-slider-content position-relative w-100 h-100 d-flex flex-column text-center text-md-left align-items-center align-items-md-start justify-content-end justify-content-sm-center">
                        <div className="d-flex flex-column mb-1_5 mt-sm-0 mb-sm-2 mb-md-3_5">
                          {largeTitle.intlKey && (
                            <div
                              className="h1 font-weight-strong large-title mx-auto mb-0_5 overflow-hidden"
                              style={{ color: titleColor || colors.white }}
                            >
                              <FormattedHTMLMessage
                                id={getIntlKeyByEnv(largeTitle.intlKey)}
                                values={{ amount: getValueFromCurrencies(largeTitle), currencyCode }}
                              />
                            </div>
                          )}

                          {smallTitle.intlKey && (
                            <div
                              className="small-title overflow-hidden"
                              style={{ color: descriptionColor || colors.white }}
                            >
                              <FormattedHTMLMessage
                                id={getIntlKeyByEnv(smallTitle.intlKey)}
                                values={{ amount: getValueFromCurrencies(smallTitle), currencyCode }}
                              />
                            </div>
                          )}
                        </div>

                        {buttonTitle && (
                          <div className="banners-slider-button-container w-100 text-center">
                            <FormattedTag
                              id={getIntlKeyByEnv(buttonTitle)}
                              {...(isBtnWithLink
                                ? {
                                  tag: isRelativeLink ? RouteLink : 'a',
                                  ...{ [isRelativeLink ? 'to' : 'href']: buttonUrl },
                                  ...(isNewTabLink
                                    ? {
                                      target: '_blank',
                                      rel: 'noopener noreferrer',
                                    }
                                    : {}),
                                }
                                : {
                                  tag: 'div',
                                  role: 'button',
                                  onClick: this.activateBonus,
                                  'data-id': getValueFromCurrencies(bonusId),
                                  'data-min': getValueFromCurrencies(minDeposit),
                                  // color: 'primary',
                                  size: 'lg',
                                })}
                              style={{
                                color: buttonTitleColor,
                                background: buttonColor,
                              }}
                              className={classNames(
                                'btn btn-lg px-4_5 px-md-6_5 mb-4 mb-sm-0 banners-slider-button',
                                {
                                  'btn-primary': !buttonColor,
                                  'btn-has-styles btn-lg': buttonColor,
                                }
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </BannerSliderItem>
                  );
                }) : null}

                {/* {!isEmptyOrNil(items) ? Object.values(items).map(item => ( */}
                {/*  <BannerTournamentSlide */}
                {/*    pixelRatio={pixelRatio} */}
                {/*    className="banners-slider-item" */}
                {/*    key={`slide-tournament_${item}`} */}
                {/*    coreProps={{ id: item }} */}
                {/*  /> */}
                {/* )) : null} */}
              </Carousel>
            </div>

            <div className="banners-slider banners-slider-bg position-absolute">
              <Carousel
                settings={{
                  infinite: true,
                  fade: true,
                  lazy: true,
                  arrows: false,
                }}
                sliderRef={this.carouselBgRef}
                iconRight={<IconArrowDown color="white" />}
                iconLeft={<IconArrowDown color="white" />}
              >
                {!isEmptyOrNil(filteredBannersItems) ? filteredBannersItems.map(({ items: { common, affiliate } }) => {
                  const item = isAffiliate && affiliate ? affiliate : common;

                  const {
                    id,
                    imageUrl,
                    imageUrlTablet,
                    imageUrlMobile,
                    characterImageUrl,
                    characterImageUrlTablet,
                    characterImageUrlMobile,
                  } = item;

                  const desktopPixelRatio = pixelRatio === '' ? '@2x' : pixelRatio;

                  const backgroundStyles = getBackgroundStyles(imageUrl, desktopPixelRatio, imageUrl);
                  const backgroundTabletStyles = getBackgroundStyles(imageUrlTablet, pixelRatio, imageUrl);
                  const backgroundMobileStyles = getBackgroundStyles(imageUrlMobile, pixelRatio, imageUrl);

                  const characterStyles = getBackgroundStyles(characterImageUrl, desktopPixelRatio, characterImageUrl);
                  const characterImageUrlTabletStyles = getBackgroundStyles(characterImageUrlTablet, pixelRatio, characterImageUrl); // eslint-disable-line max-len
                  const characterImageUrlMobileStyles = getBackgroundStyles(characterImageUrlMobile, pixelRatio, characterImageUrl); // eslint-disable-line max-len

                  return (
                    <div key={id} className="banners-slider-item w-100">
                      <>
                        {/* MOBILE */}
                        <div
                          style={backgroundMobileStyles}
                          className="banners-slider-item-background position-absolute w-100 h-100 d-sm-none"
                        />

                        {/* TABLET */}
                        <div
                          style={backgroundTabletStyles}
                          className="banners-slider-item-background position-absolute w-100 h-100 d-none d-sm-block d-md-none"
                        />

                        {/* DESKTOP */}
                        <div
                          style={backgroundStyles}
                          className="banners-slider-item-background position-absolute w-100 h-100 d-none d-md-block"
                        />

                        {/* CHARACTER MOBILE */}
                        <div
                          style={characterImageUrlMobileStyles}
                          className="character-image position-absolute d-block d-sm-none"
                        />

                        {/* CHARACTER TABLET */}
                        <div
                          style={characterImageUrlTabletStyles}
                          className={classNames('character-image position-absolute d-none d-sm-block d-md-none')}
                        />

                        {/* CHARACTER DESKTOP */}
                        <div
                          style={characterStyles}
                          className={classNames('character-image position-absolute d-none d-md-block')}
                        />
                      </>
                    </div>
                  );
                }) : null}

                {/* {!isEmptyOrNil(items) ? Object.values(items).map(item => ( */}
                {/*  <BannerTournamentSlide */}
                {/*    isBannerSliderBg */}
                {/*    pixelRatio={pixelRatio} */}
                {/*    className="banners-slider-item" */}
                {/*    key={`slide-tournament_${item}`} */}
                {/*    coreProps={{ id: item }} */}
                {/*  /> */}
                {/* )) : null} */}
              </Carousel>
            </div>
          </>
        )}
      </div>
    );
  }
}

export const BannersSlider = withCoreComponent(
  TournamentsBannersListCore,
  withBonusActions(
    withModalActions(
      withUser(withPixelRatio(BannersSliderUI), [
        USER_FIELDS.IS_USER_LOGGED_IN,
        USER_FIELDS.IS_SIGN_UP_COMPLETED,
        USER_FIELDS.IS_USER_REGISTERED,
        USER_FIELDS.USER_DATA,
        USER_FIELDS.DEFAULT_CURRENCY,
        USER_FIELDS.USER_TRACKING_CODES,
      ]),
    )
  ),
);
