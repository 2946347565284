import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { withGameActions } from 'core/hocs';
import { GAME_ITEM_KEYS, GAME_SCREEN_MODE } from 'core/constants';
import { isEmptyOrNil } from 'core/helpers';

import { Carousel } from 'components/carousel/carousel';
import { GameCard } from 'components/game-card/game-card';
import { GamesGridPreloader } from '../games-grid-preloader/games-grid-preloader';

import './games-carousel.scss';

const { ID, PRODUCER } = GAME_ITEM_KEYS;

const settings = {
  arrows: false,
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 719,
      settings: {
        slidesToShow: 2.22,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 9999,
      settings: {
        arrows: true,
      },
    },
  ],
};

const getMultipleRowsSettings = rows => ({
  rows,
  lazyLoad: null,
  arrows: false,
  responsive: [
    {
      breakpoint: 719,
      settings: {
        slidesPerRow: 2,
        slidesToShow: 1.11,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesPerRow: 4,
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 9999,
      settings: {
        arrows: true,
        slidesPerRow: 5,
        slidesToShow: 1,
      },
    },
  ],
});

class GamesCarouselUI extends Component {
  static propTypes = {
    games: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
    openGame: PropTypes.func.isRequired,
    toggleFavoriteGame: PropTypes.func.isRequired,
    isInProgress: PropTypes.bool,
    rows: PropTypes.number,
    withStubs: PropTypes.bool,
    privateSettings: PropTypes.shape(),
    isGamePage: PropTypes.bool,
    setScreenMode: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isInProgress: false,
    rows: 1,
    withStubs: false,
    privateSettings: {},
    isGamePage: false
  };

  state = {
    slidesPerRow: undefined,
    showSlider: false,
  };

  componentDidMount() {
    const { rows, privateSettings } = this.props;

    this.breakpointFound = !isEmptyOrNil(privateSettings)
      ? this.findBreakpoint(privateSettings)
      : this.findBreakpoint(settings);

    if (rows > 1) {
      const foundBreakpoint = this.findBreakpoint(getMultipleRowsSettings(rows));

      if (foundBreakpoint) {
        this.setState({
          slidesPerRow: foundBreakpoint.settings.slidesPerRow,
          showSlider: true,
        });
      }
    } else {
      this.setState({ showSlider: true });
    }
  }

  findBreakpoint = (carouselSettings) => {
    if (typeof window !== 'undefined') {
      return carouselSettings.responsive.find(({ breakpoint }) => window.innerWidth <= breakpoint);
    }

    return null;
  };

  prepareGameList = () => {
    const { games } = this.props;

    const foundBreakpoint = this.breakpointFound;

    const slidesToShow = foundBreakpoint && foundBreakpoint.settings.slidesToShow
      ? Math.floor(foundBreakpoint.settings.slidesToShow)
      : settings.slidesToShow;

    if (games.length < slidesToShow) {
      const stubs = new Array(slidesToShow - games.length).fill({});

      return [...games, ...stubs];
    }

    return games;
  };

  setSingleScreenMode = () => {
    const { setScreenMode } = this.props;

    setScreenMode(GAME_SCREEN_MODE.SINGLE);
  }

  render() {
    const {
      games,
      isUserLoggedIn,
      toggleFavoriteGame,
      rows,
      isInProgress,
      withStubs,
      privateSettings,
      openGame,
      isGamePage
    } = this.props;
    const { slidesPerRow, showSlider } = this.state;

    const preparedGamesList = withStubs ? this.prepareGameList() : games;

    const currentSettings = !isEmptyOrNil(privateSettings)
      ? privateSettings
      : settings;

    let carouselSettings = {
      ...(rows > 1 ? getMultipleRowsSettings(rows) : currentSettings),
    };

    if (slidesPerRow || rows > 1) {
      carouselSettings = {
        ...carouselSettings,
        slidesPerRow,
      };
    }

    return (isEmptyOrNil(preparedGamesList) || isInProgress || !showSlider)
      ? (
        <GamesGridPreloader
          itemsCount={carouselSettings.slidesPerRow || carouselSettings.slidesToShow}
          rows={rows}
        />
      )
      : (
        <div
          className={classNames('games-carousel mx-lg-n3 px-lg-3', {
            'has-multiple-rows': rows > 1,
          })}
        >
          <Carousel
            settings={carouselSettings}
          >
            {preparedGamesList.map(game => (
              <div
                key={`${game[ID]}-${game[PRODUCER]}`}
                className="games-carousel-item"
              >
                <GameCard
                  {...game}
                  openGame={openGame}
                  setScreenMode={this.setSingleScreenMode}
                  isGamePage={isGamePage}
                  isUserLoggedIn={isUserLoggedIn}
                  toggleFavoriteGame={toggleFavoriteGame}
                  withJackpotAmount
                />
              </div>
            ))}
          </Carousel>
        </div>
      );
  }
}

export const GamesCarousel = withGameActions(GamesCarouselUI);
