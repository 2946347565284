import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import * as R from 'ramda';
import {
  NavigationBanners as NavigationBannersCore,
  AllGames as AllGamesCore,
  FormattedTag,
  RouteLink
} from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { isEmptyOrNil } from 'core/helpers';
import { Carousel } from 'components/carousel/carousel';
import { getBackgroundStyles } from 'helpers/images';
import { withPixelRatio } from 'hocs/with-pixel-ratio';

import './navigation-banners.scss';

const settings = {
  dots: false,
  arrows: false,
  infinite: false,
  slidesToShow: 2.81,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1.7,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1.9,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2.8,
      },
    },
    {
      breakpoint: 719,
      settings: {
        slidesToShow: 3.1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2.7,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1020,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1340,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 9999,
      settings: {
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 5,

      },
    },
  ]
};

const getCardGradientColor = (index) => {
  const colors = ['grey', 'green', 'purple', 'red', 'blue'];

  return `gradient-${colors[index % colors.length]}`;
};

// const countItemsByCategory = (items, cb) => {
//   setTimeout(() => {
//     const categoryCount = {
//       sport: 0,
//       live: 0,
//       roulette: 0,
//       slot: 0,
//       card: 0
//     };

//     items.forEach((item) => {
//       item.categories.forEach((category) => {
//         const gameCategory = category.toLowerCase();

//         if (gameCategory in categoryCount) {
//           categoryCount[gameCategory] += 1;
//         }

//         if (item.isLive) {
//           categoryCount.live += 1;
//         }
//       });
//     });

//     cb(Object.values(categoryCount));
//   });
// };

class NavigationBannersUI extends PureComponent {
  // state = {
  //   categoryCount: {
  //     sport: 0,
  //     live: 0,
  //     roulette: 0,
  //     slot: 0,
  //     card: 0,
  //   },
  // };

  // componentDidMount() {
  //   const { allGames } = this.props;

  //   countItemsByCategory(
  //     allGames,
  //     result => this.setState({ categoryCount: result })
  //   );
  // }

  render() {
    const {
      navigationBannersItems,
      className,
      isInProgress,
      pixelRatio
    } = this.props;

    if (isInProgress && isEmptyOrNil(navigationBannersItems)) {
      return (
        <div className="d-flex">
          {R.times(row => (
            <div key={row} className={classNames('preloader navigation-banners-preloader ml-1_5', className)} />
          ), 2)}
        </div>
      );
    }

    if (!isInProgress && isEmptyOrNil(navigationBannersItems)) {
      return null;
    }

    return (
      <div className={classNames('navigation-banners position-relative', className)}>
        <Carousel settings={settings}>
          {(navigationBannersItems || []).map((item, index) => {
            const {
              // buttonColor,
              // buttonTitle,
              // buttonTitleColor,
              buttonUrl,
              id,
              imageUrl,
              imageUrlMobile,
              imageUrlTablet,
              isNewTabLink,
              title,
              titleColor,
            } = item;

            // const buttonStyles = {
            //   ...(isEmptyOrNil(buttonColor) ? {} : { background: buttonColor }),
            //   ...(isEmptyOrNil(buttonTitleColor) ? {} : { color: buttonTitleColor }),
            // };

            const titleStyles = {
              ...(titleColor ? { color: titleColor } : {}),
            };

            // const hasButtonStyles = !isEmptyOrNil(buttonStyles);
            const hasTitleStyles = !isEmptyOrNil(titleStyles);
            const isRelativeLink = (buttonUrl || '').startsWith('/');

            const backgroundImageStyles = getBackgroundStyles(imageUrl, pixelRatio, imageUrl);
            const backgroundImageTabletStyles = getBackgroundStyles(imageUrlTablet, pixelRatio, imageUrl);
            const backgroundImageMobileStyles = getBackgroundStyles(imageUrlMobile, pixelRatio, imageUrl);

            const cardGradientColor = getCardGradientColor(index);
            // const categoryGamesCount = this.state.categoryCount[index];

            return (
              <Button
                key={id}
                className="navigation-banners-card position-relative d-flex flex-column align-items-center justify-content-end text-white px-2 pt-1_75 pt-sm-3"
                {...{
                  tag: isRelativeLink ? RouteLink : 'a',
                  ...{ [isRelativeLink ? 'to' : 'href']: buttonUrl },
                  ...(isNewTabLink ? {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                  } : {}),
                }}
              >
                <div className={`navigation-banners-card-details position-absolute w-100 h-100 d-flex align-items-end px-2 pb-2_5 ${cardGradientColor}`}>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <FormattedTag
                      id={title}
                      className="mw-100 h4 md-h5 text-truncate navigation-banners-title text-white"
                      {...(hasTitleStyles && { style: titleStyles })}
                    />
                    {/* <div className="games-count pl-2 h5 text-white font-weight-medium position-relative"> */}
                    {/* {categoryGamesCount} */}
                    {/* <div className="h6 text-lowercase font-weight-medium"> */}
                    {/* <FormattedTag id="games" /> */}
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>

                {/* <Button */}
                {/*  {...{ */}
                {/*    tag: isRelativeLink ? RouteLink : 'a', */}
                {/*    ...{ [isRelativeLink ? 'to' : 'href']: buttonUrl }, */}
                {/*    ...(isNewTabLink ? { */}
                {/*      target: '_blank', */}
                {/*      rel: 'noopener noreferrer' */}
                {/*    } : {}), */}
                {/*  }} */}
                {/*  {...(hasButtonStyles && { style: buttonStyles })} */}
                {/*  color="primary" */}
                {/*  className="navigation-banners-card-btn mw-100 mt-0_75 mt-sm-1_5 text-truncate" */}
                {/* > */}
                {/*  <FormattedTag id={buttonTitle} /> */}
                {/* </Button> */}

                {/* MOBILE */}
                <div
                  style={backgroundImageMobileStyles}
                  className="navigation-banners-card-background position-absolute w-100 d-sm-none"
                />

                {/* TABLET */}
                <div
                  style={backgroundImageTabletStyles}
                  className="navigation-banners-card-background position-absolute w-100 d-none d-sm-block d-md-none"
                />

                {/* DESKTOP */}
                <div
                  style={backgroundImageStyles}
                  className="navigation-banners-card-background position-absolute w-100 d-none d-md-block"
                />
              </Button>
            );
          })}
        </Carousel>
      </div>
    );
  }
}

NavigationBannersUI.propTypes = {
  navigationBannersItems: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  isInProgress: PropTypes.bool,
  pixelRatio: PropTypes.string,
  allGames: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

NavigationBannersUI.defaultProps = {
  navigationBannersItems: [],
  isInProgress: false,
  className: '',
  pixelRatio: '',
};

export const NavigationBanners = withCoreComponent(
  NavigationBannersCore,
  withCoreComponent(
    AllGamesCore,
    withPixelRatio(NavigationBannersUI)
  )
);
