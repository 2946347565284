import React from 'react';
import PropTypes from 'prop-types';

import { TOURNAMENT_STATUSES } from 'core/constants';

import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { NavigationBanners } from 'components/navigation-banners/navigation-banners';
import { SportsQuickLinks } from 'components/sports-quick-links/sports-quick-links';
// import { MostPopularGames } from 'components/most-popular-games/most-popular-games';
import { TopGames } from 'components/top-games/top-games';
import { NewestGames } from 'components/newest-games/newest-games';
import { RecentlyPlayedGames } from 'components/recently-played-games/recently-played-games';
import { Producers } from 'components/producers/producers';
import { PromoTournaments } from 'components/promo-tournaments/promo-tournaments';
import { PromoPwaInstall } from 'components/promo-pwa-install/promo-pwa-install';

export const HomeContent = ({ isUserLoggedIn }) => (
  <>
    <NavigationBanners className="mb-5" />

    <SportsQuickLinks className="mt-2_5 mt-lg-3_75 mb-5" />

    {/* Top Games */}
    {/* <MostPopularGames coreProps={{ size: 200 }} /> */}
    <TopGames
      className="mt-2_5 mt-lg-3_75"
    />

    {/* Latest Games */}
    <NewestGames coreProps={{ size: 100, filteringDisabled: true }} />

    {/* Recently Played */}
    {isUserLoggedIn && <RecentlyPlayedGames coreProps={{ size: 100 }} className="mb-3 mb-lg-5" />}

    {/* Providers */}
    <SkipServerRender skip>
      <Producers className="my-2_5 my-lg-3_75" />
    </SkipServerRender>

    {/* Tournaments */}
    <PromoTournaments
      coreProps={{
        availableStatuses: [TOURNAMENT_STATUSES.ACTIVE, TOURNAMENT_STATUSES.PENDING, TOURNAMENT_STATUSES.AWARDED]
      }}
      isOnlySliderType
      isHomePage
    />

    <SkipServerRender skip>
      <PromoPwaInstall />
    </SkipServerRender>
  </>
);

HomeContent.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
};
