import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import classNames from 'classnames';
import { isEmptyOrNil } from 'core/helpers';
import { withGameActions, withCoreComponent } from 'core/hocs';
import { TopGames as TopGamesCore, FormattedTag, RouteLink } from 'core/components';
import { GAMES_CATEGORY_PAGE } from 'core/constants';
import { GamesCarousel } from 'components/games-carousel/games-carousel';

const TopGamesUI = ({
  topGamesItems,
  isInProgress,
  isUserLoggedIn,
  className
}) => {
  if (!isInProgress && isEmptyOrNil(topGamesItems)) {
    return null;
  }

  return (
    <div className={classNames('top-games mx-lg-n3 px-lg-3 my-2_5 my-lg-3_75', className)}>
      <div className="d-flex justify-content-between align-items-center mb-1_75 mb-md-2_5">
        <div className="d-flex align-items-center">
          <FormattedTag
            id="games.most-popular"
            className="ml-1 text-large font-weight-bold letter-spacing-default text-gray-40"
          />
        </div>
        {topGamesItems?.length > 10 && (
          <Button color="link" className="mr-md-12 d-flex align-items-center">
            <FormattedTag
              id="see-all"
              tag={RouteLink}
              to={`/category/${GAMES_CATEGORY_PAGE.TOP_GAMES}`}
              className="text-base"
            />
          </Button>
        )}
      </div>

      <GamesCarousel
        isInProgress={isInProgress}
        isUserLoggedIn={isUserLoggedIn}
        games={topGamesItems}
        rows={2}
      />
    </div>
  );
};

TopGamesUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isInProgress: PropTypes.bool,
  topGamesItems: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

TopGamesUI.defaultProps = {
  topGamesItems: [],
  isInProgress: false,
  className: '',
};

export const TopGames = withCoreComponent(TopGamesCore, withGameActions(TopGamesUI));
