import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import * as R from 'ramda';
import {
  SportsQuickLinks as SportsQuickLinksCore,
  FormattedTag,
  RouteLink
} from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { isEmptyOrNil } from 'core/helpers';
import { Carousel } from 'components/carousel/carousel';
import { IconCarouselArrow } from 'components/icons/icon-carousel-arrow/icon-carousel-arrow';
import './sports-quick-links.scss';

const settings = {
  rows: 2,
  lazyLoad: null,
  arrows: false,
  responsive: [
    {
      breakpoint: 580,
      settings: {
        slidesPerRow: 1,
        slidesToShow: 1.5,
      },
    },
    {
      breakpoint: 719,
      settings: {
        slidesPerRow: 2,
        slidesToShow: 1.4,
      },
    },
    {
      breakpoint: 880,
      settings: {
        slidesPerRow: 2,
        slidesToShow: 1.5,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesPerRow: 4,
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1279,
      settings: {
        slidesPerRow: 5,
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 9999,
      settings: {
        arrows: true,
        slidesPerRow: 5,
        slidesToShow: 1,
      },
    },
  ],
};

class SportsQuickLinksUI extends PureComponent {
  render() {
    const { sportsQuickLinksItems, className, isInProgress } = this.props;

    if (isInProgress && isEmptyOrNil(sportsQuickLinksItems)) {
      return (
        <div className={classNames('sports-quick-links-wrapper-preloader overflow-hidden w-100', className)}>
          {R.times(row => (
            <div key={row} className="sports-quick-links-preloader preloader" />
          ), 10)}
        </div>
      );
    }

    if (!isInProgress && isEmptyOrNil(sportsQuickLinksItems)) {
      return null;
    }

    return (
      <div className={classNames('sports-quick-links position-relative', className)}>
        <FormattedTag tag="p" id="sports-quick-links.title" className="h3 mb-1" />
        <Carousel settings={settings}>
          {(sportsQuickLinksItems || []).map((item) => {
            const {
              buttonColor,
              buttonTitle,
              buttonTitleColor,
              buttonUrl,
              id,
              iconUrl,
              isNewTabLink,
            } = item;

            const buttonStyles = {
              ...(isEmptyOrNil(buttonColor) ? {} : { background: buttonColor }),
              ...(isEmptyOrNil(buttonTitleColor) ? {} : { color: buttonTitleColor }),
            };

            const hasButtonStyles = !isEmptyOrNil(buttonStyles);
            const isRelativeLink = (buttonUrl || '').startsWith('/');

            return (
              <Button
                key={id}
                {...{
                  tag: isRelativeLink ? RouteLink : 'a',
                  ...{ [isRelativeLink ? 'to' : 'href']: buttonUrl },
                  ...(isNewTabLink ? {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                  } : {}),
                }}
                {...(hasButtonStyles && { style: buttonStyles })}
                color="transparent"
                className="sports-quick-links-card d-flex align-items-center justify-content-between py-1_5 px-2"
              >
                <img src={iconUrl} alt="" className="sports-quick-links-card-icon mr-2" />
                <FormattedTag className="sports-quick-links-card-title text-truncate text-capitalize mr-auto" id={buttonTitle} />

                <IconCarouselArrow className="sports-quick-links-card-icon-arrow" />
              </Button>
            );
          })}
        </Carousel>
      </div>
    );
  }
}

SportsQuickLinksUI.propTypes = {
  sportsQuickLinksItems: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  isInProgress: PropTypes.bool,
};

SportsQuickLinksUI.defaultProps = {
  sportsQuickLinksItems: [],
  isInProgress: false,
  className: ''
};

export const SportsQuickLinks = withCoreComponent(
  SportsQuickLinksCore, SportsQuickLinksUI
);
