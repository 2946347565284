import React from 'react';
import PropTypes from 'prop-types';

export const IconRecentlyPlayed = ({ className }) => (
  <svg className={className} width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="#ffffff" fillRule="evenodd" clipRule="evenodd" d="M13.8449 0.198119C13.2389 0.155097 12.7176 0.611787 12.6804 1.21816C12.6433 1.82454 13.1045 2.35099 13.7105 2.39401C19.5451 2.80824 24.0965 8.27252 23.6994 14.7598C23.3023 21.247 18.1241 26.0206 12.2895 25.6063C6.45489 25.1921 1.90343 19.7278 2.30055 13.2406C2.42884 11.145 3.06024 9.22086 4.05444 7.59309L11.5728 15.8738C12.0554 16.4054 12.8776 16.445 13.4091 15.9624C13.9407 15.4798 13.9804 14.6576 13.4977 14.1261L2.76888 2.3093C2.28626 1.77774 1.4641 1.73807 0.932541 2.22069C0.400981 2.70331 0.361308 3.52547 0.84393 4.05703L2.51719 5.89996C1.1413 7.93811 0.270441 10.3997 0.106076 13.0848C-0.355814 20.6301 4.95946 27.2914 12.1551 27.8022C19.3507 28.3131 25.432 22.4609 25.8939 14.9156C26.3558 7.37027 21.0405 0.708976 13.8449 0.198119Z" />
  </svg>
);

IconRecentlyPlayed.propTypes = {
  className: PropTypes.string,
};

IconRecentlyPlayed.defaultProps = {
  className: null,
};
