import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Banners as BannersCore } from 'core/components';
import { TOURNAMENT_STATUSES } from 'core/constants';
import { withCoreComponent } from 'core/hocs';

import { BannersSlider } from './banners-slider/banners-slider';

export const MainBannerUI = ({ className, bonusesItems, isLiveGamesCategory }) => (
  <div className={classNames('position-relative', className)}>
    <BannersSlider
      coreProps={{ availableStatuses: [TOURNAMENT_STATUSES.ACTIVE, TOURNAMENT_STATUSES.PENDING] }}
      bonusesItems={bonusesItems}
      isLiveGamesCategory={isLiveGamesCategory}
    />
  </div>
);

MainBannerUI.propTypes = {
  bonusesItems: PropTypes.arrayOf(PropTypes.shape()),
  isLiveGamesCategory: PropTypes.bool,
  className: PropTypes.string,
};

MainBannerUI.defaultProps = {
  bonusesItems: [],
  isLiveGamesCategory: false,
  className: ''
};

export const MainBanner = withCoreComponent(
  BannersCore, MainBannerUI
);
