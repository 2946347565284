/* eslint-disable max-len, react/no-unused-prop-types, react/require-default-props */
import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { withUserAgent, withUser, withLocale } from 'core/hocs';
import { DEVICES_TYPES, USER_FIELDS } from 'core/constants';
import { FormattedTag } from 'core/components';
import colors from 'customizations/js/color-variables';

import { PwaInstallContext } from 'hocs/with-pwa-install';

import { PwaInstallFromMobileModal } from 'components/modals/pwa-install-modal/pwa-install-from-mobile-modal';
import { PwaInstallFromDesktopModal } from 'components/modals/pwa-install-modal/pwa-install-from-desktop-modal';
import { GA } from '../../helpers/ga';
import { IconIos } from '../icons/icon-ios/icon-ios';
import { IconAndroid } from '../icons/icon-android/icon-android';
import { MODAL_IDS } from '../../constants';

import './promo-pwa-install.scss';

class PromoPwaInstallUI extends Component {
  static propTypes = {
    deviceType: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    isUserRegistered: PropTypes.bool.isRequired,
    isPwa: PropTypes.bool.isRequired,
    isDeferredPromptReady: PropTypes.bool,
    os: PropTypes.string,
    browserName: PropTypes.string,
    openModal: PropTypes.func,
    onInstallClick: PropTypes.func,
    isPwaGuideReady: PropTypes.bool,
  };

  static contextType = PwaInstallContext;

  openModal = () => {
    console.log('this.context', this.context);
    this.context.openModal(MODAL_IDS.PWA_INSTALL);
  };

  onInstallClick = () => {
    GA.event({ category: 'App', action: 'click', label: 'App' });
    this.context.onInstallClick(MODAL_IDS.PWA_INSTALL_MOBILE);
  };

  onMobileInstallClick = () => {
    const { onInstallClick, hidePwaInstallGuide } = this.context;

    hidePwaInstallGuide();

    GA.event({ category: 'App', action: 'click', label: 'App' });
    onInstallClick(MODAL_IDS.PWA_INSTALL_MOBILE);
  };

  render() {
    const {
      deviceType,
      // isUserRegistered,
    } = this.props;

    const {
      os,
      isDeferredPromptReady,
      browserName,
      isIncognito,
      isPwa,
    } = this.context;

    if (isPwa) {
      return null;
    }

    const isDesktop = deviceType === DEVICES_TYPES.DESKTOP;
    const isAndroid = os === 'android';
    const isIOs = os === 'ios';
    const isChromeBrowser = browserName === 'Chrome';
    const isMobileInstallBtnDisabled = !isDesktop && !isDeferredPromptReady && isAndroid && isChromeBrowser;
    const MobileDeviceIconComp = isIOs ? IconIos : IconAndroid;

    return (
      <>
        <div className="promo-pwa-install rounded position-relative bg-primary-gradient">
          <div className="promo-pwa-install-content d-flex flex-column align-items-center align-items-sm-start px-2 py-4 px-sm-6 px-md-10_75 pt-sm-9_25">
            <div className="promo-pwa-install-title h2 text-uppercase text-center text-sm-left mb-1 mb-sm-2 text-white">
              <FormattedHTMLMessage id="promo.pwa-install.title">
                {titleValue => (
                  <div dangerouslySetInnerHTML={{ __html: titleValue }} />
                )}
              </FormattedHTMLMessage>
            </div>

            <div className="promo-pwa-install-desc text-base font-weight-medium text-center text-sm-left mb-3 mb-sm-5 text-white">
              <FormattedHTMLMessage id="promo.pwa-install.desc" />
            </div>

            <>
              {/* MOBILE */}
              <div
                role="button"
                tabIndex="0"
                className={classNames('promo-pwa-install-btn-mobile promo-pwa-install-btn d-flex d-sm-none justify-content-center align-items-center text-uppercase font-weight-strong letter-spacing-n0_5 text-dark-violet text-center', {
                  disabled: isMobileInstallBtnDisabled,
                  'cursor-pointer': !isMobileInstallBtnDisabled,
                })}
                onClick={isDesktop ? this.openModal : this.onInstallClick}
                onKeyPress={isDesktop ? this.openModal : this.onInstallClick}
              >
                <MobileDeviceIconComp
                  className={classNames('promo-pwa-install-btn-mobile-icon mr-1_25', {
                    android: !isIOs,
                    ios: isIOs,
                  })}
                  color={colors.darkViolet}
                />

                <>
                  <FormattedTag id="new-comers.banner.desktop.btn" className="text-black" />
                  &nbsp;
                  <FormattedTag id={isIOs ? 'ios' : 'android'} className="text-black" />
                </>
              </div>

              {/* DESKTOP */}
              <div className="d-none d-sm-flex position-absolute promo-pwa-install-btn">
                {/* IOS */}
                <div
                  role="button"
                  tabIndex="0"
                  onClick={this.openModal}
                  onKeyPress={this.openModal}
                  className="promo-pwa-install-btn-desktop ios d-flex align-items-center bg-white rounded px-2_25 mr-2_75"
                >
                  <IconIos className="promo-pwa-install-btn-desktop-icon ios position-relative mr-2" />

                  <div className="promo-pwa-install-btn-desktop-text d-flex flex-column">
                    <FormattedTag
                      id="new-comers.banner.desktop.btn"
                      className="promo-pwa-install-btn-desktop-text-title font-weight-medium text-black mb-0_25"
                    />

                    <FormattedTag
                      id="ios"
                      className="promo-pwa-install-btn-desktop-text-os font-weight-strong text-black"
                    />
                  </div>
                </div>

                {/* ANDROID */}
                <div
                  role="button"
                  tabIndex="0"
                  onClick={this.openModal}
                  onKeyPress={this.openModal}
                  className="promo-pwa-install-btn-desktop android d-flex align-items-center bg-white rounded px-2_25"
                >
                  <IconAndroid className="promo-pwa-install-btn-desktop-icon android position-relative mr-1_75" />

                  <div className="promo-pwa-install-btn-desktop-text d-flex flex-column">
                    <FormattedTag
                      id="new-comers.banner.desktop.btn"
                      className="promo-pwa-install-btn-desktop-text-title font-weight-medium text-black mb-0_25"
                    />

                    <FormattedTag
                      id="android"
                      className="promo-pwa-install-btn-desktop-text-os font-weight-strong text-black"
                    />
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
        <PwaInstallFromDesktopModal />
        <PwaInstallFromMobileModal
          coreProps={{ id: MODAL_IDS.PWA_INSTALL_MOBILE }}
          isIncognito={isIncognito}
          onInstallClick={this.onMobileInstallClick}
        />
      </>
    );
  }
}

export const PromoPwaInstall = withUserAgent(
  withUser(
    withLocale(
      PromoPwaInstallUI
    ), [
      USER_FIELDS.IS_USER_REGISTERED,
    ]
  )
);
/* eslint-enable max-len, react/no-unused-prop-types, react/require-default-props */
