import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconCarouselArrow = ({ className, color, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M13.5773 19.2683L23.5776 6.95471C23.9087 6.56101 24.4363 6.57231 24.756 6.97996C25.0679 7.37763 25.0679 8.00804 24.756 8.40565L15.3449 19.9938L24.756 31.5819C25.0813 31.9826 25.0813 32.6322 24.756 33.0329C24.4305 33.4335 23.903 33.4335 23.5776 33.0329L13.5773 20.7193C13.252 20.3185 13.252 19.669 13.5773 19.2683Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

IconCarouselArrow.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};
IconCarouselArrow.defaultProps = {
  className: '',
  color: colors.gray40,
  onClick: null,
};
