import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { isEmptyOrNil } from 'core/helpers';
import { RecentlyPlayedGames as RecentlyPlayedGamesCore, FormattedTag, RouteLink } from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { GAMES_CATEGORY_PAGE } from 'core/constants';
import { GamesCarousel } from 'components/games-carousel/games-carousel';

import './recently-played-games.scss';
import { IconRecentlyPlayed } from '../icons/icon-recently-played/icon-recently-played';

export const RecentlyPlayedGamesUI = ({
  games, isUserLoggedIn, className, isInProgress, isGamePage
}) => isUserLoggedIn && !isEmptyOrNil(games)
  ? (
    <div className={className}>
      <div className="d-flex justify-content-between align-items-center mb-1_75 mb-md-2_5">
        <div className="d-flex align-items-center">
          {isGamePage && <IconRecentlyPlayed />}
          <FormattedTag id="games.recently-played" className="ml-1 text-large font-weight-bold letter-spacing-default text-white" />
        </div>

        {games.length > 10 && (
          <Button color="link" className="mr-md-12 d-flex align-items-center">
            <FormattedTag id="see-all" tag={RouteLink} to={`/category/${GAMES_CATEGORY_PAGE.RECENTLY_PLAYED}`} className="text-base" />
          </Button>
        )}
      </div>
      <GamesCarousel
        isInProgress={isInProgress}
        isUserLoggedIn={isUserLoggedIn}
        isGamePage={isGamePage}
        games={games}
      />
    </div>
  )
  : null;

RecentlyPlayedGamesUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  games: PropTypes.arrayOf(PropTypes.shape({})),
  isGamePage: PropTypes.bool,
  className: PropTypes.string,
};

RecentlyPlayedGamesUI.defaultProps = {
  games: [],
  className: null,
  isGamePage: false
};

export const RecentlyPlayedGames = withCoreComponent(RecentlyPlayedGamesCore, RecentlyPlayedGamesUI);
