import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { NewestGames as NewestGamesCore, FormattedTag, RouteLink } from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { GAMES_CATEGORY_PAGE } from 'core/constants';
import { GamesCarousel } from 'components/games-carousel/games-carousel';

import './newest-games.scss';

export const NewestGamesUI = ({
  games, isUserLoggedIn, className, isInProgress
}) => (
  <div className={classNames('newest-games mx-lg-n3 px-lg-3 my-2_5 my-lg-3_75', className)}>
    <div className="d-flex justify-content-between align-items-center mb-1_75 mb-md-2_5">
      <div className="d-flex align-items-center">
        <FormattedTag
          id="games.newest"
          className="ml-1 text-large font-weight-bold letter-spacing-default text-gray-40"
        />
      </div>
      {games.length > 10 && (
        <Button color="link" className="mr-md-12 d-flex align-items-center">
          <FormattedTag
            id="see-all"
            tag={RouteLink}
            to={`/category/${GAMES_CATEGORY_PAGE.NEWEST}`}
            className="text-base"
          />
        </Button>
      )}
    </div>

    <GamesCarousel
      isInProgress={isInProgress}
      isUserLoggedIn={isUserLoggedIn}
      games={games}
      rows={2}
    />
  </div>
);

NewestGamesUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  games: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

NewestGamesUI.defaultProps = {
  games: [],
  className: null,
};

export const NewestGames = withCoreComponent(NewestGamesCore, NewestGamesUI);
